:root {
  --bs-primary: rgb(44, 153, 206);
  --bs-primary-rbg: rgb(44, 153, 206);
  --bs-blue: rgb(44, 153, 206);
}

.btn-primary {
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-bg: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-primary);
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);

}

.text-primary {
  color: var(--bs-primary) !important;
}

.border.border-primary,
.border-bottom.border-primary {
  border-color: var(--bs-primary) !important;
}

.bg-primary {
  background-color: var(--bs-primary) !important;
}

body {
  margin: 0;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: rgba(0,0,0,1);        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(255,255,255,0.2);    /* color of the scroll thumb */
  border-radius: 2px;       /* roundness of the scroll thumb */
  border: 5px solid rgba(0,0,0,1);  /* creates padding around scroll thumb */
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

ul li div {
  width:  87px;
  text-align:  right;
}


.hex {
    overflow: hidden;
    visibility: hidden;
    -webkit-transform: rotate(120deg);
       -moz-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
         -o-transform: rotate(120deg);
            transform: rotate(120deg);
    cursor: pointer;
    }
.in1 {
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(-60deg);
       -moz-transform: rotate(-60deg);
        -ms-transform: rotate(-60deg);
         -o-transform: rotate(-60deg);
            transform: rotate(-60deg);
    }
.in2 {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    visibility: visible;
    font-size: 0.5em;
    text-shadow: 0px 0px 3px black;

    -webkit-transform: rotate(-60deg);
       -moz-transform: rotate(-60deg);
        -ms-transform: rotate(-60deg);
         -o-transform: rotate(-60deg);
            transform: rotate(-60deg);
    }

.in2:hover {
  opacity: 1;
}

/* .grass {
  background-color:  green;
  background-image: url('./Slowquest_WorldmapHextiles/hex_grass_plain[hex\,grass\,plain].png');
}

.canyon {
  background-color: red;
  background-image: url('./canyon.jfif');
}

.desert {
  background-color: goldenrod;
  background-image: url('./Slowquest_WorldmapHextiles/hex_desert_plain[hex\,desert\,plain].png');
}

.forest{
  background-color: darkgreen;
  background-image: url('./Slowquest_WorldmapHextiles/hex_forest_plain[hex\,forest\,plain].png');
}

.glacier {
  background-color: white;
  background-image: url('./Slowquest_WorldmapHextiles/hex_snow_plain[hex\,snow\,plain].png');
}
.hill {
  background-color: lightgreen;
  background-image: url('./Slowquest_WorldmapHextiles/hex_grass_hills[hex\,grass\,hill].png');
}
.marsh {
  background-color: darkslategray;
  background-image: url('./Slowquest_WorldmapHextiles/hex_swamp_marshes1[hex\,swamp\,marshes].png');
}
.mountain {
  background-color: darkgreen;
  background-image: url('./Slowquest_WorldmapHextiles/hex_forest_mountains1[hex\,forest\,mountain].png');
}
.oasis {
  background-color: lightblue;
  background-image: url('./Slowquest_WorldmapHextiles/hex_desert_oasis[hex\,desert\,oasis].png');
}
.ocean {
  background-color: darkblue;
  background-image: url('./Slowquest_WorldmapHextiles/hex_water_rough[hex\,water\,rough].png');
}
.swamp {
  background-color: darkturquoise;
  background-image: url('./Slowquest_WorldmapHextiles/hex_swamp_plain[hex\,swamp\,plain].png');
}
.tundra {
  background-color: orange;
  background-image: url('./Slowquest_WorldmapHextiles/hex_snow_plains[hex\,snow\,plains].png');
} */

.in2 span {
  position:  absolute;
  top:  80px;
  left:  25px;
}

.hex-1 {
    width: 77px;
    height: 92px;
    margin: 49px -10px -80px 10px;
}

.site-title {
  font-size: 2rem;
}

.round-profile {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 2px solid rgba(255,255,255,0.2);
  background-size: contain;
}

.round-profile button{
  opacity: 0;
}

.lonely {
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

#dice-box,
#dice-box canvas {
  position: fixed;
  pointer-events: none;
  z-index: 100;
  width: 100%;
  height: 100%;
}

.campaign-characters {
  transform: translate(-50%, -50%);
}

h1, h2, h3, h4, h5, h6, .brand, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: 'Calistoga', cursive;
}

.splash {
  background-image: url('./assets/1.jpg');
  background-image: -webkit-linear-gradient(rgba(0,0,0, 0) 50%,rgba(0,0,0, 1) 100%), url('./assets/1.jpg');
  background-image:  -moz-linear-gradient(rgba(0,0,0, 0) 50%,rgba(0,0,0, 1) 100%), url('./assets/1.jpg');
  background-image:  -o-linear-gradient(rgba(0,0,0, 0) 50%,rgba(0,0,0, 1) 100%), url('./assets/1.jpg');
  background-image:  -ms-linear-gradient(rgba(0,0,0, 0) 50%,rgba(0,0,0, 1) 100%), url('./assets/1.jpg');
  background-image:  linear-gradient(rgba(0,0,0, 0) 50%,rgba(0,0,0, 1) 100%), url('./assets/1.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 90vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shield {
  border-radius: 50% 50% 50% 50% / 12% 12% 88% 88%;
}

.rotate {
  animation: rotation 2s infinite ease-in-out;
}

#dice-box {
  z-index: 3000;
}

.toc a {
  color: black;
  text-decoration: none;
}

/* .toc a.active {
  color: var(--bs-primary);
} */

.toc .list-group-item {
  border: none;
}

.None .row div:first-child {
  font-style: italic;
}

.Oversized .row div:first-child {
  font-style: italic;
  font-weight: bold;
}

.btn-close {
  background-color: #222;
  border-radius: 100%;
}

.navbar-nav .nav-link.active {
  color: var(--bs-primary);
}